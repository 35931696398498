<template>
	<div class="tools">


		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />

		<v-row class="pa-4">

			<v-col cols="12" sm="12" md="4" v-for="tool in tools" :key="tool.name">

				<v-card class="glassmorphism pa-4" color="transparent" style="overflow: hidden">

					<v-icon class="iconOverflow" color="white">{{tool.icon}}</v-icon>

					<Title
						icon
						:mdi="tool.icon"
						titleColor="white"
						iconColor="white"
					>{{ tool.name }}</Title>

					<v-card class="mt-4 px-4" height="150" outlined>

						<v-card-text class="px-0">
							{{ tool.description }}
						</v-card-text>

						<v-btn class="evidence neon" :class="$vuetify.breakpoint.xsOnly ? '' : 'absolute right bottom ma-4'" :block="$vuetify.breakpoint.xsOnly" outlined :color="$vuetify.theme.dark ? 'white' : 'primary'"
						width="200"
							:to="tool.link">
							Accéder
						</v-btn>

					</v-card>

				</v-card>

			</v-col>

		</v-row>

	</div>
</template>

<script>
	import Head from '@/components/graphics/head'
	import Title from '@/components/graphics/title'

	export default {
		head: {
			title: {
				inner: "Outils"
			}
		},
		name: 'Outils',
		components: {
			Head,
			Title
		},
		data() {
			return {

				Head: {

					Title: 'Outils',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "20vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},

				tools: [{
						name: 'Générateur de QR Codes',
						icon: 'mdi-qrcode',
						description: 'Outil permettant la génération et la manipulation de QR Codes.',
						link: 'tools/qrcode'
					},
					{
						name: 'Import CSV',
						icon: 'mdi-file',
						description: 'Outil permettant l\'import de CSV dans la base de données produits.',
						link: 'tools/import'
					}
				]
			};
		},
	}

</script>
